export default {
  props: ['video_url'],
  mounted() {
    this.initPlayer();
  },
  data() {
    return {};
  },
  methods: {
    initPlayer() {
      const _this = this;
      // eslint-disable-next-line no-undef
      new Aliplayer({
        id: 'J_prismPlayer',
        width: '100%',
        height: '100%',
        source: this.video_url,
        //视频的播放凭证，可以在通用媒体管理服务控制台获取。
        autoplay: true,
        preload: true,
        playsinline: true,
        skinLayout: [{
          name: "bigPlayButton",
          align: "cc"
        }, {
          name: "H5Loading",
          align: "cc"
        }, {
          name: "errorDisplay",
          align: "tlabs",
          x: 0,
          y: 0
        }, {
          name: "infoDisplay"
        }, {
          name: "tooltip",
          align: "blabs",
          x: 0,
          y: 56
        }, {
          name: "thumbnail"
        }, {
          name: "controlBar",
          align: "blabs",
          x: 0,
          y: 0,
          children: [
          // {name: "progress", align: "blabs", x: 0, y: 20},
          {
            name: "playButton",
            align: "tl",
            x: 15,
            y: 12
          }, {
            name: "timeDisplay",
            align: "tl",
            x: 10,
            y: 7
          },
          // {name: "fullScreenButton", align: "tr", x: 10, y: 12},
          {
            name: "volume",
            align: "tr",
            x: 5,
            y: 10
          }]
        }]
      }, function (player) {
        player.on('ended', () => {
          _this.$emit('videoEndEvent');
        });
      });
    }
  }
};